html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  height: auto;
  width: 100vw;
  box-sizing: border-box;
  user-select: none;
  -webkit-user-select: none; /*Safari*/
  -moz-user-select: none; /*Firefox*/
}

body {
  margin: 0;
  background-image: url("https://imagedelivery.net/RT_RPMu352DnmQo9WAcrJw/24c31262-860f-4758-6532-01bfe1bd3800/backgrounddesktop");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Montserrat", Sans-serif;
  position: relative;
  height: 100%;
}

.Components {
  max-width: 75rem;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  z-index: 1;
  position: relative;
  gap: 16px;
}

.accordion-item {
  margin-bottom: 3rem;
}

.nav-list {
  margin-bottom: 1rem;
  padding: 0 1rem;
  justify-content: center;
}

#faq {
  color: yellow;
  margin-bottom: 0;
}

.bannerimage {
  max-width: 100%;
  height: auto;
}

.mintedqty {
  top: -3%;
}

.fqonLZ {
width: 90px !important;
}

.react-3d-carousel .slider-container .slider-left div,
.react-3d-carousel .slider-container .slider-right div {
  border-top: 1px solid #fdc84b !important;
  border-right: 1px solid #fdc84b !important;
  border-bottom: 1px solid #fdc84b !important;
  border-left: 1px solid #fdc84b !important;
  box-shadow: 0 0 10px #fdc84b;
  border-radius: 10%;
}

.carouselimg {
  width: auto;
  height: 100%;
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .mobile-no-display {
    display: none;
  }

  .Components {
    margin-top: 2rem;
  }
}

@media only screen and (max-width: 600px) {
  .preview-video2 {
    display: none;
  }
}

@media only screen and (min-width: 320px) and (max-width: 425px) {
  body {
    background-color: #1a1818;
    background-image: none;
  }
}

@keyframes glowing {
  0% {
    background-color: #fffd9c;
    box-shadow: 0 0 5px #fffd9c;
  }
  50% {
    background-color: #fffd9c;
    box-shadow: 0 0 20px #fffd9c;
  }
  100% {
    background-color: #fffd9c;
    box-shadow: 0 0 5px #fffd9c;
  }
}
